@import "npm:normalize.css";
@import "npm:@blueprintjs/core/lib/css/blueprint.css";
@import "./dashboard/tags.css";
@import "npm:firebaseui/dist/firebaseui.css";
@import "npm:@tdisc/video/dist/style.css";
@import url("https://fonts.googleapis.com/css2?family=Days+One&display=swap");
@import url("https://storage.googleapis.com/techdisc-cdn/fonts/hco_fonts.css");
@import "npm:rsuite/dist/rsuite.min.css";
@import "./localStyles.less";

:root {
  --gold: #ffd700;
  --gold-text: #b8860b;
  --gold-shadow: #654321;
  --silver: #e6e8fa;
  --silver-text: #707070;
  --silver-shadow: #424242;
  --bronze: #cd7f32;
  --bronze-text: #8b4513;
  --bronze-shadow: #5c4033;
}

/* Undo Blueprint focus styles */
*:focus {
  outline: none;
}

* {
  font-family: "HCo Gotham SSm", sans-serif;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

a:hover {
  color: "inherit";
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

/*    <color name="colorPrimary">#2A81DE</color>
    <color name="colorPrimaryDark">#14143D</color>
    <color name="colorDarkBlue">#12375E</color>
    <color name="colorAccentGreen">#ACE06C</color>
    <color name="colorAccentTeal">#27DDD4</color>
    <color name="colorAccentPink">#E775F5</color>
    <color name="colorGreyDark">#3E3E3C</color>
    <color name="colorGreyMed">#666666</color>
    <color name="colorGreyLight">#BFBFBF</color>
    */

/*.bp3-table-cell-ledger-even {*/
/*  background: #405060;*/
/*}*/

/*.bp3-table-cell-ledger-odd {*/
/*  background: #293b49;*/
/*}*/

/* fix the position of the 3d utils gui menu */
#guiMenu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex: none;
}

.rs-picker-daterange-menu {
  z-index: 10000000000 !important;
  /* added a large random number, you can choose it more carefully based on your use-case.*/
}
